import axios from 'axios';
import OktaAuth from '@okta/okta-auth-js';
import _omit from 'lodash/omit';
import { captureException } from '@sentry/react';
import { OMITTED_URLS } from '@app/constants/axios';
import { getOktaConfig } from '@app/okta.config';

const oktaAuth = new OktaAuth(getOktaConfig().oidc);
const baseConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  retry: 1,
};

const coreClient = axios.create({
  baseURL: '/api/core',
  ...baseConfig,
});

const insightsClient = axios.create({
  baseURL: `/api/reporting`,
  ...baseConfig,
});

const cmsClient = axios.create({
  baseURL: `/api/bennie-cms`,
  ...baseConfig,
});

const uiServicesClient = axios.create({
  baseURL: `/api/ui-services`,
  ...baseConfig,
});

const marketplaceApiClient = axios.create({
  baseURL: `/api/marketplace-partners`,
  ...baseConfig,
});

const findADoctorClient = axios.create({
  baseURL: `/api/findcare/v1`,
  ...baseConfig,
});

// // this will eventually be its own thing
// const ribbonHealthClient = axios.create({
//   baseURL: `${process.env.BENNIE_API_GATEWAY_BASE_URL}/api/findcare/v1`,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

const requestInterceptor = config => {
  const accessToken = oktaAuth.getAccessToken();
  const omitHeaders = OMITTED_URLS.some(omittedUrl =>
    config.url.includes(omittedUrl),
  );

  if (omitHeaders) {
    return {
      ...config,
      // Excludes Authorization header for endpoints listed in the
      // OMITTED_URLS array
      headers: _omit(config.headers, 'Authorization'),
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

const errorInterceptor = client => async err => {
  const { config, response } = err;
  if (!config || !config.retry || !response) {
    return Promise.reject(err);
  }

  const retryCodes = [401, 403, 404];

  if (config.retry && retryCodes.includes(response.status)) {
    const token = await oktaAuth.tokenManager
      .renew('accessToken')
      .catch(error => {
        return Promise.reject(error);
      });

    if (!token.accessToken) {
      return Promise.reject(Error('Could not refresh access token'));
    }

    config.retry = config.retry ? config.retry - 1 : 0;
    config.headers.Authorization = `Bearer ${token.accessToken}`;
    config.retries = config.retries || 1;

    return new Promise(resolve => {
      resolve(client({ ...config, headers: config.headers.toJSON() }));
    });
  }

  captureException(err);
  return Promise.reject(err);
};

[
  coreClient,
  insightsClient,
  cmsClient,
  uiServicesClient,
  marketplaceApiClient,
  findADoctorClient,
].forEach(client => {
  client.interceptors.request.use(requestInterceptor);
  client.interceptors.response.use(null, errorInterceptor(client));
});

export {
  coreClient,
  insightsClient,
  cmsClient,
  uiServicesClient,
  marketplaceApiClient,
  findADoctorClient,
};
