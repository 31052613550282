import { uiServicesClient } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const fetchSavedFacilities = async () => {
  try {
    const response = await uiServicesClient.get(`/facilities`);
    if (responseFailed(response.status)) {
      throw new Error(response.statusText || 'Error fetching saved facilities');
    }
    return getResponseData(response);
  } catch (e) {
    throw new Error(e);
  }
};

export const saveFacility = async facility => {
  const response = await uiServicesClient.post(`/facilities`, {
    ...facility,
    name: facility.displayName,
  });

  if (responseFailed(response.status)) {
    throw new Error(response.statusText || 'Error saving facility');
  }

  return getResponseData(response);
};

export const deleteSavedFacility = async id => {
  const response = await uiServicesClient.delete(`/facilities/${id}`);

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Could not delete saved facility');
  }

  return getResponseData(response);
};
