/* eslint-disable no-underscore-dangle */

export const getOktaConfig = () => {
  const isLocal =
    window.location.hostname.startsWith('192.168') &&
    window.location.port === '3000';

  return {
    oidc: {
      clientId: window._env_.OKTA_CLIENT_ID,
      issuer: window._env_.OKTA_ISSUER,
      redirectUri: `${window.location.origin}/implicit/callback`,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      pkce: !isLocal,
      disableHttpsCheck: isLocal,
      tokenManager: {
        storageKey: 'bennie-web-token-storage',
      },
    },
  };
};
