import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';

import createReducer from '@app/reducers';
import rootSaga from '@app/store/saga';
import { bennieApi } from '@app/store/bennieApi';

export const history = createBrowserHistory();

export function createStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const rootReducer = createReducer();
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        sagaMiddleware,
        bennieApi.middleware,
      ),
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = createStore();

export default store;
