import {
  curatedBlogPostsQuery,
  telehealthLogoQuery,
} from '@benniehealth/core-js';

import { isProd } from '@app/helpers/environment';
import { cmsClient } from '@app/helpers/requests/client';
import { responseDataAdapter } from '@app/helpers/requests/bennieFetch';

export const fetchBlogPosts = ({ queryParams }) => {
  const query = curatedBlogPostsQuery(
    queryParams,
    !isProd(window.location.hostname),
  );

  return cmsClient
    .post(`/blog-content`, { query })
    .then(response => responseDataAdapter(response));
};

export const fetchTelehealthLogoQuery = ({ providerIds }) => {
  const query = telehealthLogoQuery(
    providerIds,
    !isProd(window.location.hostname),
  );

  return cmsClient
    .post(`/app-content`, { query })
    .then(response => responseDataAdapter(response));
};
