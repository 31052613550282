export const isStaging = (hostName = '') => {
  return hostName.toLowerCase().search(`stg.bennie.com`) >= 0;
};

export const isDev = (hostName = '') => {
  return hostName.toLowerCase().search(`dev.bennie.com`) >= 0;
};

export const isProd = (hostName = '') => {
  return (
    !isStaging(hostName) &&
    !isDev(hostName) &&
    hostName.toLowerCase().search(`bennie.com`) >= 0
  );
};
