import { marketplaceApiClient, coreClient } from '@app/helpers/requests/client';
import {
  responseDataAdapter,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const fetchEmployeeMarketplace = () => {
  return marketplaceApiClient
    .get(`/employee-marketplace-partners`)
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not fetch employee marketplace partners');
      }

      return responseDataAdapter(response);
    });
};

export const fetchEmployerMarketplace = () => {
  return marketplaceApiClient
    .get(`/employer-marketplace-partners`)
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not fetch employer marketplace partners');
      }

      return responseDataAdapter(response);
    });
};

export const sendContactRequest = partnerName => {
  return coreClient
    .post(`/v1/admin/employermarketplace/contact`, {
      partnerName,
    })
    .then(response => {
      const { status } = response;

      if (responseFailed(status)) {
        throw new Error('Could not complete request');
      }

      return responseDataAdapter(response);
    });
};
