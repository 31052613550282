import OktaAuth from '@okta/okta-auth-js';
import { getOktaConfig } from '@app/okta.config';

const oktaAuth = new OktaAuth(getOktaConfig().oidc);

export function getAccessToken() {
  return oktaAuth.getAccessToken();
}

export function getIdToken() {
  return oktaAuth.getIdToken();
}

export async function getClaims() {
  try {
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    return accessToken.claims;
  } catch (error) {
    console.error('Failed to get claims:', error);
    return null;
  }
}

export function refreshToken() {
  return oktaAuth.tokenManager.renew('accessToken');
}
