import { OktaAuth } from '@okta/okta-auth-js';
import { getOktaConfig } from '../../okta.config';

export const getOktaAuthClient = () => {
  const oktaConfig = getOktaConfig();
  const oktaAuthClient = new OktaAuth({
    issuer: oktaConfig.oidc.issuer,
    tokenManager: {
      storageKey: 'bennie-web-token-storage',
    },
  });

  return oktaAuthClient;
};

export const queryParams = params => {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
};

export const backendErrorsArrayIntoString = async (
  resp,
  errSeparatorStr,
  defaultErrMsg,
) => {
  const { errors } = await resp.json();
  const errorMessage = Array.isArray(errors)
    ? errors.join(errSeparatorStr || ' ')
    : defaultErrMsg || '';

  throw new Error(errorMessage);
};

export const handleResponseStatus = (
  respStatusCode,
  expectedStatusCode,
  onSuccessFn = () => null,
  onFailFn = () => null,
) => {
  if (
    typeof expectedStatusCode === 'number' &&
    respStatusCode === expectedStatusCode
  ) {
    return onSuccessFn();
  }

  if (
    Array.isArray(expectedStatusCode) &&
    expectedStatusCode.some(code => code === respStatusCode)
  ) {
    return onSuccessFn();
  }

  return onFailFn();
};

export const responseSuccess = status =>
  handleResponseStatus(status, [200, 204], () => true, () => false);

export const responseFailed = status => !responseSuccess(status);

export const responseDataAdapter = response => {
  return {
    ...response,
    json: () => Promise.resolve(getResponseData(response)),
    ok: responseSuccess(response.status),
  };
};

export const getResponseData = response => response.data;
