import { uiServicesClient } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const saveSession = async () => {
  const response = await uiServicesClient.post('/sessions/web', {});
  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error saving session');
  }

  return getResponseData(response);
};
