import { put, takeEvery, take, select, call, all } from 'redux-saga/effects';
import { profile } from '@benniehealth/core-js';
import { setUser as setSentryUser } from '@sentry/react';
import {
  disable as analyticsDisable,
  identify as analyticsIdentify,
  setGroup as analyticsSetGroup,
  registerOnce as analyticsRegisterOnce,
  setProfile as analyticsSetProfile,
} from '@app/helpers/analytics';

import {
  checkCompanyAccessRequest,
  checkCompanyAccessSuccess,
  checkCompanyAccessError,
} from '@app/store/companyAccesses';

import {
  fetchClientAttributesRequest,
  fetchClientAttributesSuccess,
  fetchClientAttributesError,
} from '@app/store/clientAttributes';

import {
  fetchDocumentsRequest,
  fetchDocumentsSuccess,
  fetchDocumentsError,
} from '@app/store/enrollmentDocuments';

import {
  saveSessionError,
  saveSessionRequest,
  saveSessionSuccess,
} from '@app/store/sessions';

import {
  fetchInitialDataRequest,
  fetchInitialDataSuccess,
  fetchInitialDataError,
} from './index';

function* runFetchProfile() {
  yield put(profile.actions.fetchProfileRequest());
  yield take([
    profile.actions.fetchProfileSuccess.type,
    profile.actions.fetchProfileError.type,
  ]);
}

function* runFetchClientAttributes() {
  yield put(fetchClientAttributesRequest());
  yield take([fetchClientAttributesSuccess, fetchClientAttributesError]);
}

function* runFetchApplicationStatus() {
  yield put(profile.actions.fetchApplicationStatusRequest());
  yield take([
    profile.actions.fetchApplicationStatusSuccess.type,
    profile.actions.fetchApplicationStatusError.type,
  ]);
}

function* runCheckCompanyAccesses() {
  yield put(checkCompanyAccessRequest());
  yield take([checkCompanyAccessSuccess, checkCompanyAccessError]);
}

function* runFetchDocuments() {
  yield put(fetchDocumentsRequest());
  yield take([fetchDocumentsSuccess, fetchDocumentsError]);
}

function* runSaveSession() {
  yield put(saveSessionRequest());
  yield take([saveSessionSuccess, saveSessionError]);
}

const setFeatureFlagUser = async (
  identify,
  { profileId, companyId, company, userId, isImpersonationSession },
) => {
  const userContext = {
    kind: 'user',
    key: profileId,
    company,
    bennieId: userId,
  };

  const companyContext = {
    kind: 'company',
    key: companyId,
    name: company,
  };

  const impersonationContext = {
    kind: 'impersonation',
    key: isImpersonationSession ? 'true' : 'false',
    value: isImpersonationSession,
  };
  const platformContext = {
    kind: 'platform',
    key: 'web',
    value: 'web',
  };

  const multiContext = {
    kind: 'multi',
    user: userContext,
    company: companyContext,
    impersonation: impersonationContext,
    platform: platformContext,
  };

  return new Promise((resolve, reject) => {
    identify(multiContext, null, (error, flags) => {
      if (error) {
        reject(error);
      } else {
        resolve(flags);
      }
    });
  });
};

const setAnalyticsUser = (
  disableAnalytics,
  { id, company, subsidiaryId, userId },
) => {
  if (disableAnalytics) {
    analyticsDisable();
  } else {
    analyticsIdentify(id);
    analyticsSetGroup('company', company);
    analyticsRegisterOnce({ subsidiaryId });
    analyticsSetProfile({ subsidiaryId, userId });
  }
};

export function* fetchInitialData({ payload }) {
  try {
    yield all([runFetchApplicationStatus(), runCheckCompanyAccesses()]);

    const [shouldLogout, isSuperAdmin, isImpersonationSession] = yield all([
      select(state => state.profile.userApplicationStatus.shouldLogout),
      select(state => state.companyAccesses.isSuperAdmin),
      select(state => state.companyAccesses.isImpersonationSession),
    ]);

    if (isSuperAdmin) {
      // don't do anything else if Super Admin. This will be false once the impersonation seession
      // starts, at which point this whole thing will run again.
      return;
    }

    if (shouldLogout && !isSuperAdmin && !isImpersonationSession) {
      payload.navigate(`/logout`);
      return;
    }

    yield all([
      runFetchProfile(),
      runFetchDocuments(),
      runFetchClientAttributes(),
    ]);

    const profileId = yield select(profile.selectors.profileIdSelector);
    const companyId = yield select(profile.selectors.getCompanyId);
    const company = yield select(
      state => state.companyAccesses.currentCompanySubdomain,
    );

    const disableAnalytics = isSuperAdmin || isImpersonationSession;
    const { subsidiaryId, userId, identify } = payload;

    // TODO: the msw tests currently reach here but no further.

    yield all([
      call(setFeatureFlagUser, identify, {
        profileId,
        companyId,
        company,
        userId,
        isImpersonationSession,
      }),
      call(setSentryUser, { id: profileId }),
      call(setAnalyticsUser, disableAnalytics, {
        id: profileId,
        company,
        subsidiaryId,
        userId,
      }),
    ]);

    if (!isImpersonationSession) {
      yield runSaveSession();
    }

    // TODO we could probably do some prefetching of images here like we do on native.

    yield put(fetchInitialDataSuccess());
  } catch (error) {
    yield put(fetchInitialDataError(error.message));
  }
}

export function* fetchInitialDataWatcher() {
  yield takeEvery(fetchInitialDataRequest, fetchInitialData);
}
