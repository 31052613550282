import { uiServicesClient } from '@app/helpers/requests/client';
import {
  getResponseData,
  responseFailed,
} from '@app/helpers/requests/bennieFetch';

export const fetchSavedProviders = async () => {
  const response = await uiServicesClient.get(`/providers`);

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error fetching saved providers');
  }

  return getResponseData(response);
};

export const saveProvider = async provider => {
  const response = await uiServicesClient.post(`/providers`, {
    ...provider,
  });
  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Error saving provider');
  }

  return getResponseData(response);
};

export const deleteSavedProvider = async id => {
  const response = await uiServicesClient.delete(`/providers/${id}`);

  const { status } = response;

  if (responseFailed(status)) {
    throw new Error(response.statusText || 'Could not delete saved provider');
  }

  return getResponseData(response);
};
