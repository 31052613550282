import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken, refreshToken } from '@app/helpers/auth/oidc';

export const createBaseQueryWithReAuth = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: headers => {
      const accessToken = getAccessToken();

      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('content-type', 'application/json');

      return headers;
    },
  });

  const baseQueryWithReAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
      await refreshToken().catch(error => {
        console.error('Failed to refresh token:', error);
        return Promise.reject(error);
      });
      result = await baseQuery(args, api, extraOptions);
      return result;
    }

    return result;
  };

  return baseQueryWithReAuth;
};
